export default {
  navContainer: ({ props }) => ({
    class: [
      // Position
      'relative',

      // Misc
      { 'overflow-hidden': props.scrollable }
    ]
  }),
  navContent: {
    class: [
      // Overflow and Scrolling
      'overflow-y-hidden overscroll-contain',
      'overscroll-auto',
      'scroll-smooth',
      '[&::-webkit-scrollbar]:hidden'
    ]
  },
  previousButton: {
    class: [
      // Flexbox and Alignment
      'flex items-center justify-center',

      // Position
      '!absolute',
      'top-0 left-0',
      'z-20',

      // Size and Shape
      'h-full w-12',
      'rounded-none',

      // Colors
      'bg-surface-800',
      'text-primary-400',
      'shadow-md'
    ]
  },
  nextButton: {
    class: [
      // Flexbox and Alignment
      'flex items-center justify-center',

      // Position
      '!absolute',
      'top-0 right-0',
      'z-20',

      // Size and Shape
      'h-full w-12',
      'rounded-none',

      // Colors
      'bg-surface-800',
      'text-primary-400',
      'shadow-md'
    ]
  },
  nav: {
    class: [
      // Flexbox
      'flex flex-1',

      // Spacing
      'list-none',
      'p-0 m-0',

      // Colors
      'bg-surface-800',
      'border-b-2 border-surface-700',
      'text-surface-0/80'
    ]
  },
  tabpanel: {
    header: ({ props }) => ({
      class: [
        // Spacing
        'mr-0',

        // Misc
        {
          'opacity-60 cursor-default user-select-none select-none pointer-events-none':
            props?.disabled
        }
      ]
    }),
    headerAction: ({ state, context }) => ({
      class: [
        'relative',

        // Font
        'font-semibold tracking-wide text-sm',

        // Flexbox and Alignment
        'flex items-center',

        // Spacing
        'px-8 py-3',
        '-mb-[2px]',

        // Shape
        'border-b-2',

        // Colors and Conditions
        {
          'border-surface-700': state.d_activeIndex !== context.index,
          'text-surface-0/80': state.d_activeIndex !== context.index,

          'border-b-primary-400': state.d_activeIndex === context.index,
          'text-primary-400': state.d_activeIndex === context.index
        },

        // States
        'focus:outline-none focus:ring-0',
        {
          'hover:bg-surface-800/80': state.d_activeIndex !== context.index,
          'hover:border-primary-400': state.d_activeIndex !== context.index,
          'hover:text-surface-0': state.d_activeIndex !== context.index,
          'hover:text-primary-500 hover:border-primary-500': state.d_activeIndex === context.index
        },

        // Transitions
        'transition-all duration-200',

        // Misc
        'cursor-pointer select-none text-decoration-none',
        'overflow-hidden',
        'user-select-none'
      ]
    }),
    headerTitle: {
      class: [
        // Text
        'leading-none',
        'whitespace-nowrap'
      ]
    },
    content: {
      class: [
        // Spacing
        'p-5',

        // Shape
        'rounded-b-md',

        // Colors
        'bg-surface-800',
        'text-surface-0/80',
        'border-0'
      ]
    }
  }
}

<script setup lang="ts">
import Toaster from '@/components/Toaster.vue'
import { useMagicKeys, whenever } from '@vueuse/core'
import { inject } from 'vue'

const keys = useMagicKeys()

// eslint-disable-next-line no-console
console.log(
  `%cNomic HQ v${inject('version')}`,
  'color: #0082F6; font-weight: bold; font-size: 16px; padding: 5px 0;'
)

// Global keybindings
whenever(keys.ctrl_k, () => {
  document?.getElementById('site-search')?.focus()
})

whenever(keys.ctrl_o, () => {
  document?.getElementById('org-picker-trigger')?.click()
})
</script>

<template>
  <router-view />
  <Toaster />
</template>

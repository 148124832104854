<script setup lang="ts">
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import { useEventBus } from '@vueuse/core'
import type { ToastMessageOptions } from 'primevue/toast'

// If you try using PrimeVue's useToast composable outside of a component,
// it will fail with 'No PrimeVue Toast provided' error.
// See the original issue and the workaround here:
// https://github.com/primefaces/primevue/issues/6403#issuecomment-2363381833

const bus = useEventBus<ToastMessageOptions>('notification')
const toaster = useToast()

bus.on((message) => {
  toaster.add(message)
})
</script>

<template>
  <Toast position="bottom-right" />
</template>
